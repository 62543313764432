@import "~react-day-picker/lib/style";

html,
body,
#app {
	height: 100%;
}

.container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
    
  .box {
      text-align: center;
      color: white;
      font-family: sans-serif;
      font-size: 36px;
      padding: 20px;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
  }

  .menu {
    height: 60px;
  }
  
  .content {
    flex: 1;
  }
}